<template>
  <v-card
    v-if="showBanner"
    :tile="dense"
    :color="background"
    :class="`banner link py-5 ${
      dense ? 'px-6' : 'px-8 mt-4'
    } d-flex justify-space-between overflow-hidden`"
    :height="bannerHeight"
    flat
    @click="redirectToSelfChekout"
  >
    <div
      class="circle rounded-circle"
      v-if="$vuetify.breakpoint.smAndUp && !isExpired && !dense"
    />
    <!-- ÍCONE DE ALERTA QUANDO O TRIAL ESTÁ EXPIRADO -->
    <v-icon
      v-if="$vuetify.breakpoint.smAndUp && isExpired"
      v-text="'mdi-alert'"
      color="white"
      size="80"
      :class="`align-self-start rotate`"
    />

    <Logo
      v-if="dense && !$vuetify.breakpoint.xs && !isExpired"
      :product="upgradeProduct"
      :black="hasBasic"
      white
    />

    <v-row
      align="center"
      justify="start"
      :class="`${
        $vuetify.breakpoint.smAndUp && !isExpired && !dense
          ? 'ml-12 pl-12'
          : 'pl-0'
      } ${$vuetify.breakpoint.smAndUp && isExpired ? 'ml-6' : 'pl-0'} ${
        dense ? 'mx-0 px-0' : ''
      }`"
    >
      <v-col
        cols="12"
        xs="12"
        :md="dense ? 6 : 7"
        :lg="dense ? 6 : 7"
        :xl="dense ? 7 : 8"
        :class="`${$vuetify.breakpoint.xs ? 'pl-0 pr-5' : ''}`"
      >
        <v-card-title
          v-if="!hideTitle"
          :class="`${$vuetify.breakpoint.smAndUp ? 'text-h5' : 'mb-6'} ${
            dense ? 'text-h6 pl-0' : ''
          } py-0 font-weight-bold ${
            dense && hasBasic ? 'grey--text text--darken-4' : 'white--text'
          } break-word`"
        >
          {{ title }}
        </v-card-title>
        <v-subheader
          :class="`my-0 py-0 text-body-2 ${
            $vuetify.breakpoint.xs ? 'mb-6' : ''
          } ${$vuetify.breakpoint.lgAndDown ? 'my-4' : ''} ${
            dense ? 'text-body-2 pl-0 my-4' : ''
          } ${
            dense && hasBasic ? 'grey--text text--darken-4' : 'white--text'
          } break-word`"
        >
          {{ subtitle }}
        </v-subheader>
        <p class="text-body-2" v-if="dense && hasBasic">
          {{ $t("upgradeBanner.standardPlanAndUp") }}
        </p>
        <v-btn
          v-if="!dense"
          elevation="0"
          :color="buttonBackground"
          id="selfCheckOutBtn"
          class="mt-2 ml-4 text-none text-body-1 font-weight-medium overflow-hidden"
          large
          rounded
          dark
          :block="$vuetify.breakpoint.xs || dense"
        >
          {{ isExpired ? $t("sidebar.hirePlan") : $t("common.knowThePlans") }}
          <v-icon v-text="'mdi-arrow-right'" class="next-icon-1" right />
        </v-btn>

        <v-btn
          v-if="!dense && !isSmallClient"
          elevation="0"
          :color="buttonBackground"
          id="scheduleMeetingBtn"
          class="mt-2 ml-4 text-none text-body-1 font-weight-medium overflow-hidden schedule-btn"
          large
          rounded
          dark
          @click.stop="scheduleMeeting"
          :block="$vuetify.breakpoint.xs || dense"
        >
          {{ $t("common.scheduleMeeting") }}
          <v-icon v-text="'mdi-arrow-right'" class="next-icon-2" right />
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        :md="dense ? 6 : 3"
        :lg="dense ? 6 : 3"
        :xl="dense ? 5 : 4"
        v-if="$vuetify.breakpoint.mdAndUp || dense"
        :class="`${
          ($vuetify.breakpoint.lg || $vuetify.breakpoint.md) && !dense
            ? 'ml-8'
            : ''
        } ${
          dense && $vuetify.breakpoint.mdAndUp
            ? 'd-flex justify-end align-center'
            : ''
        }`"
      >
        <v-btn
          v-if="dense"
          elevation="0"
          :color="buttonBackground"
          :class="` ${
            dense ? '' : 'ml-4 mt-2'
          } text-none text-body-1 font-weight-medium overflow-hidden`"
          large
          rounded
          dark
          :block="$vuetify.breakpoint.xs || dense"
        >
          {{ isExpired ? $t("sidebar.hirePlan") : $t("common.knowThePlans") }}
          <v-icon v-text="'mdi-arrow-right'" class="next-icon" right />
        </v-btn>

        <ProductPlanIcons
          v-else
          :planSlug="upgradeSlug"
          :width="$vuetify.breakpoint.lg || $vuetify.breakpoint.md ? 80 : 100"
          :height="$vuetify.breakpoint.lg || $vuetify.breakpoint.md ? 75 : 95"
          :custom-class="$vuetify.breakpoint.lgAndUp ? 'mx-4' : 'mx-2'"
          custom-style="opacity: 0.9"
          hide-products
          white
          style="z-index: 100"
        />
      </v-col>
    </v-row>
    <div
      :class="`circle-2 rounded-circle ${bigCircleBackground}`"
      v-if="$vuetify.breakpoint.xl && !isExpired && !dense"
    />
    <div
      class="circle-3 rounded-circle"
      v-if="$vuetify.breakpoint.sm && !isExpired && !dense"
    />
    <div
      class="circle-4 rounded-circle"
      v-if="$vuetify.breakpoint.md && !isExpired && !dense"
    />
    <v-icon
      v-if="$vuetify.breakpoint.smAndUp && !isExpired && !dense"
      v-text="'mdi-dots-grid'"
      color="white"
      size="40"
      :class="`align-self-end ${$vuetify.breakpoint.xs ? 'ml-6' : ''}`"
    />
    <v-icon
      v-if="$vuetify.breakpoint.smAndUp && isExpired && !dense"
      v-text="'mdi-emoji-dead'"
      color="white"
      size="40"
      :class="`align-self-end ${$vuetify.breakpoint.xs ? 'ml-6' : ''}`"
    />
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import ProductPlanIcons from "@/components/general/ProductPlanIcons.vue";
import { STATUS } from "@/helpers/variables/backendConstants.js";
import { redirectToTheCommercialManagerGoogleCalendar } from "@/helpers/services/utils";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";

export default {
  name: "UpgradeBanner",
  props: {
    dense: { type: Boolean, default: false },
    hideTitle: { type: Boolean, default: false },
  },
  data() {
    return {
      STATUS,
    };
  },
  computed: {
    ...mapGetters([
      "hasSecurity",
      "hasEnterprise",
      "hasBasic",
      "hasStandard",
      "clientActivePlans",
      "currentPlan",
      "company",
      "mainDomain",
      "currentUser",
      "isSmallClient",
    ]),

    showBanner() {
      if (this.isHomePage) {
        return this.planIsInTrial;
      }
      return this.hasBasic || this.hasStandard || this.planIsInTrial;
    },

    planIsInTrial() {
      return this.currentPlan && this.currentPlan.status === STATUS.TRIAL;
    },

    title() {
      return this.planIsInTrial
        ? this.trialTitle
        : this.showBanner
        ? this.$t("upgradeBanner.upgradePlan")
        : "";
    },

    subtitle() {
      return this.planIsInTrial
        ? this.trialSubtitle
        : this.showBanner
        ? this.subtitleUpgradeText
        : "";
    },

    subtitleUpgradeText() {
      if (this.dense) {
        return this.hasBasic
          ? this.$t("upgradeBanner.subtitleUpgradeTextDense")
          : this.$t("upgradeBanner.subtitleUpgradeTextNotDense");
      }
      return this.hasBasic
        ? this.$t("upgradeBanner.subtitleUpgradeTextHasBasic")
        : this.$t("upgradeBanner.subtitleUpgradeTextNotBasic");
    },

    isExpired() {
      return this.currentPlan?.valid_status === STATUS.TRIAL_EXPIRED;
    },

    trialSubtitle() {
      return this.isExpired
        ? this.$t("upgradeBanner.trialSubtitleExpired")
        : this.$t("upgradeBanner.trialSubitlePeriod");
    },

    trialTitle() {
      return this.isExpired
        ? this.$t("upgradeBanner.trialTitleExpired")
        : this.$t("upgradeBanner.trialTitlePeriod");
    },

    background() {
      if (this.isExpired) {
        return this.dense ? "expired-banner-dense" : "expired-banner";
      } else if (this.hasBasic) {
        return this.dense ? "standard-banner-dense" : "standard-banner";
      } else if (this.hasStandard) {
        return this.dense ? "security-banner-dense" : "security-banner";
      }
      return this.dense ? "main-banner-dense" : "main-banner";
    },

    bigCircleBackground() {
      if (this.hasBasic) {
        return "standard-border";
      } else if (this.hasStandard) {
        return "security-border";
      }
      return "nuvem-border";
    },

    buttonBackground() {
      if (this.isExpired) {
        return "error";
      } else if (this.hasBasic) {
        return "accent";
      } else if (this.hasStandard) {
        return "accentControl";
      }
      return "accentNuvem darken-1";
    },

    upgradeSlug() {
      if (this.isExpired) {
        return "security";
      } else if (this.hasBasic) {
        return "standard";
      } else if (this.hasStandard) {
        return "security";
      }
      return "enterprise";
    },

    upgradeProduct() {
      if (this.hasBasic) {
        return "conecta_sign";
      } else if (this.hasStandard) {
        return "conecta_control";
      }
      return "";
    },

    bannerHeight() {
      if (this.dense) {
        return "";
      }
      return this.$vuetify.breakpoint.lg ? 200 : "";
    },

    isHomePage() {
      return this.$route.name === "Home";
    },
  },

  methods: {
    ...mapMutations(["setIsHomePage"]),

    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened from upgrade banner";
      const clicked_from = "Banner de upgrade";

      logEvent(analytics, "schedule_meeting", {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },

    redirectToSelfChekout() {
      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: "upgrade_banner",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: `Clicou no botão 'Conhecer os planos' do banner da ${
          this.isHomePage ? "página inicial" : "página de perfil da empresa"
        }`,
      });

      this.$router.push({ name: "BillingUpgradePlan" });
    },
  },
  components: { ProductPlanIcons },

  beforeMount() {
    this.setIsHomePage(true);
  },
};
</script>
<style>
@media (min-width: 1024px) {
  .security-banner {
    background: linear-gradient(110deg, #4670b4 60%, #517bbd 60%) !important;
  }
}
@media (max-width: 1023px) {
  .security-banner {
    background: #4670b4 !important;
  }
}

.security-banner-dense {
  background: linear-gradient(110deg, #4670b4 60%, #517bbd 40%) !important;
}

@media (max-width: 768px) {
  .security-banner-dense {
    background: #4670b4 !important;
  }
}

@media (min-width: 1024px) {
  .standard-banner {
    background: linear-gradient(110deg, #fabb09 60%, #f5c33b 60%) !important;
  }
}
@media (max-width: 1023px) {
  .standard-banner {
    background: #fabb09 !important;
  }
}

.standard-banner-dense {
  background: linear-gradient(110deg, #fabb09 60%, #f3c64c 60%) !important;
}

@media (max-width: 768px) {
  .standard-banner-dense {
    background: #fabb09 !important;
  }
}

@media (min-width: 1024px) {
  .main-banner {
    background: linear-gradient(110deg, #4b3a7e 60%, #8571c2 60%) !important;
  }
}
@media (max-width: 1023px) {
  .main-banner {
    background: #4b3a7e !important;
  }
}

@media (min-width: 1904px) {
  .main-banner-dense {
    background: linear-gradient(110deg, #4b3a7e 80%, #52408a 80%) !important;
  }
}

@media (min-width: 1023px) {
  .main-banner-dense {
    background: linear-gradient(110deg, #4b3a7e 55%, #52408a 55%) !important;
  }
}

@media (max-width: 1022px) {
  .main-banner-dense {
    background: #4b3a7e !important;
  }
}

@media (min-width: 1024px) {
  .expired-banner {
    background: linear-gradient(110deg, #b33d3d 60%, #dd5858 60%) !important;
  }
}
@media (max-width: 1023px) {
  .expired-banner {
    background: #b33d3d !important;
  }
}

.expired-banner-dense {
  background: #b33d3d !important;
}

.circle {
  width: 150px;
  height: 150px;
  border: 25px solid #fff;
  margin: -70px 0 0 -62px;
  position: absolute;
}
.circle-2 {
  width: 250px;
  height: 250px;
  border-width: 35px;
  border-style: solid;
  margin: 0 -80px -60px 0;
  position: absolute;
  right: 0;
}
.circle-3 {
  width: 150px;
  height: 150px;
  border: 20px solid #8571c2;
  margin: 0 -45px -50px 0;
  position: absolute;
  right: 0;
  bottom: 0;
}
.circle-4 {
  width: 150px;
  height: 150px;
  border: 15px solid #4b3a7e;
  margin: 0 -55px -70px 0;
  position: absolute;
  right: 0;
  bottom: 0;
}

.nuvem-border {
  border-color: #4b3a7e !important;
}

.standard-border {
  border-color: #f1cb60 !important;
}

.security-border {
  border-color: #4670b4 !important;
}

.rotate {
  transform: rotate(340deg);
}
.banner:hover > .rotate {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

#scheduleMeetingBtn:hover .next-icon-2 {
  transform: translateX(50%);
}

#selfCheckOutBtn:hover .next-icon-1 {
  transform: translateX(50%);
}
</style>
