<template>
  <div>
    <v-expansion-panels v-model="panel" multiple flat class="ms-1">
      <v-expansion-panel :disabled="loading" class="outlined">
        <v-progress-linear
          :active="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>

        <v-expansion-panel-header
          class="grey--text text--darken-2 font-weight-medium text-body-1"
        >
          <div>Mensagem de Férias</div>
          <v-spacer />
          <v-play-video-button
            title="Mensagem de Férias"
            id_youtube_video="EOTnyfR2Tjs"
            justify="end"
            class="mr-2"
          />
        </v-expansion-panel-header>

        <v-expansion-panel-content style="position: relative">
          <VacationSettingsForm :payload="payload" />
          <v-tooltip :disabled="userHasGmail" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div style="width: fit-content" v-bind="attrs" v-on="on">
                <v-btn
                  :disabled="
                    !canSaveVacationSetting || loading || !userHasGmail
                  "
                  :loading="loadingUpdate"
                  color="accent"
                  class="mt-4 elevation-0 text-none font-weight-medium text-body-2 white--text"
                  @click="updateVacation"
                >
                  Salvar alterações
                </v-btn>
              </div>
            </template>
            <span v-if="!userHasGmail">
              A caixa de e-mail deste usuário não está ativa.
            </span>
          </v-tooltip>
          <OverlayScopes
            :overlay="overlay"
            :title="$t('sidebar.vacationMessages')"
            :functionality="$t('sidebar.vacationMessages')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <Snackbar
      :show="showSnackBarSuccess"
      message="Operação realizada com sucesso."
      notShowCloseButton
      @show="showSnackBarSuccess = !showSnackBarSuccess"
    />
    <Snackbar
      :show="showSnackBarError"
      message="Erro ao realizar operação."
      notShowCloseButton
      color="error"
      @show="showSnackBarError = !showSnackBarError"
    />
  </div>
</template>

<script>
import VacationSettingsForm from "@/components/main/shared/VacationSettingsForm.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import OverlayScopes from "../general/OverlayScopes.vue";

export default {
  name: "VacationResponder",

  props: {
    id: { type: String, default: "" },
    user: { type: Object, required: true },
    isPanelOpened: { type: Boolean, default: false },
  },

  components: { VacationSettingsForm, OverlayScopes },

  data() {
    return {
      panel: [this.isPanelOpened ? 0 : 1],
      loading: false,
      loadingUpdate: false,
      showSnackBarSuccess: false,
      showSnackBarError: false,
      selectedTemplate: null,
      templateId: null,
      payload: {
        enable_auto_reply: false,
        response_subject: null,
        response_body_plain_text: null,
        response_body_html: null,
        restrict_to_contacts: false,
        restrict_to_domain: false,
        start_time: null,
        end_time: null,
        create_calendar_event: false,
        calendar_event_name: "Férias ⛱️",
      },
      overlay: false,
    };
  },

  computed: {
    ...mapGetters(["token", "hasConectaSign", "users"]),

    userEmail() {
      if (this.id) {
        const user = this.users.find((user) => user.id_google == this.id);
        return user ? user.email : "";
      }
      return "";
    },

    userHasGmail() {
      return this.user.is_gmail_enabled;
    },

    canSaveVacationSetting() {
      const {
        enable_auto_reply,
        response_subject,
        response_body_html,
        start_time,
        end_time,
        forwarding_email_on_vacation,
      } = this.payload;

      if (!enable_auto_reply) {
        return true;
      }

      if (
        forwarding_email_on_vacation &&
        typeof forwarding_email_on_vacation !== "string"
      ) {
        return false;
      }

      return [
        enable_auto_reply,
        response_subject,
        response_body_html,
        start_time,
        end_time,
      ].reduce((result, value) => {
        result = result && !!value;
        return result;
      }, true);
    },
  },

  watch: {
    id: {
      handler() {
        if (this.id && this.userHasGmail && this.isPanelOpened) {
          this.getVacation();
        }
      },
      immediate: true,
    },

    panel(_, newV) {
      if (this.userHasGmail && this.id && newV == 1) {
        this.getVacation();
      }
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),
    ...mapActions(["updateVacationInBatch"]),

    getVacation() {
      this.loading = true;
      const url =
        process.env.VUE_APP_API_BASE_URL + `/user/${this.id}/vacation`;

      this.$axios
        .get(url, {
          headers: {
            Authorization: this.token,
          },
        })
        .then((response) => {
          this.payload = { ...this.payload, ...response.data };
        })
        .catch((err) => {
          console.error("getVacation()", err);
          const { unknown } = errorMessages;
          if (err.response.data.code === "INVALID_GOOGLE_ACCESS_TOKEN") {
            this.overlay = true;
          } else {
            this.setSnackBar({
              show: true,
              message: unknown,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateVacation() {
      this.loadingUpdate = true;

      const url =
        process.env.VUE_APP_API_BASE_URL + `/user/${this.id}/vacation`;

      const headers = {
        Authorization: this.token,
      };

      // Salvar novo template
      if (this.hasConectaSign && this.selectedTemplate) {
        const payload = {
          users: [this.userEmail],
          vacation_template_key: this.templateId,
          template: this.selectedTemplate,
          vacation: this.payload,
        };

        this.updateVacationInBatch(payload)
          .then((response) => {
            this.payload = { ...this.payload, ...response.data };
            this.showSnackBarSuccess = true;
          })
          .catch((error) => {
            console.error("updateVacation():" + error);
            this.showSnackBarError = true;
          })
          .finally(() => {
            this.loadingUpdate = false;
          });
      } else {
        let payload = {
          enable_auto_reply: this.payload.enable_auto_reply,
          ...(!this.payload.enable_auto_reply ? {} : this.payload),
        };

        this.$axios
          .patch(url, payload, { headers })
          .then((response) => {
            this.payload = { ...this.payload, ...response.data.response };
            this.showSnackBarSuccess = true;
          })
          .catch((error) => {
            console.error("updateVacation():" + error);
            this.showSnackBarError = true;
          })
          .finally(() => {
            this.loadingUpdate = false;
          });
      }
    },
  },

  beforeMount() {
    if (this.id && this.userHasGmail && this.isPanelOpened) {
      this.getVacation();
    }
  },
};
</script>
